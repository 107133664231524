import React from "react"
// import Media from "react-media"
import "./text-over-image.css"

class TextOverImage extends React.PureComponent {
  render() {
    return (
      <div className="container">
        <img src={this.props.imageSrcDesktop} className="tool-img" alt="" />
        <div className="centered">
          <h2 className="translucent-background-for-text">{this.props.text}</h2>
        </div>
      </div>
    )
  }
}

export default TextOverImage
