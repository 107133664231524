/**
 * load iframe-resizer if the app is being loaded in an iframe
 * */
export const loadResizer = () => {
  if (window.location !== window.parent.location) {
    const head = document.getElementsByTagName("head")[0]
    const script = document.createElement("script")
    script.type = "text/javascript"
    script.src = "https://cdn.jsdelivr.net/npm/iframe-resizer@4.3.2/js/iframeResizer.contentWindow.min.js"
    head.appendChild(script)
  }
}
