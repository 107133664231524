import * as React from "react"
import { Link } from "react-router-dom"
import { styled } from "@mui/material/styles"
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp"
import MuiAccordion from "@mui/material/Accordion"
import MuiAccordionSummary from "@mui/material/AccordionSummary"
import MuiAccordionDetails from "@mui/material/AccordionDetails"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import Button from "@mui/material/Button"

import constructionHatLogo from "../../images/icons/constructionHatLogo.svg"
import calculatorLogo from "../../images/icons/calculatorLogo.svg"
import contractLogo from "../../images/icons/contractLogo.svg"
import personLogo from "../../images/icons/personLogo.svg"
import scaleLogo from "../../images/icons/scaleLogo.svg"
import vaccineLogo from "../../images/icons/vaccineLogo.svg"
import { Pages } from "@mui/icons-material"
import { Divider } from "@mui/material"

// Generalize this component to be used with other landing Pages. Ie HarassmentTool

const data = [
  {
    title: "Worker Classification",
    disc1:
      "Whether you’re a worker or an employer you should know what rights and benefits you or your worker’s may be entitled to, our tool can help you quickly find out!",
    note1: "Estimated completion time: 5 minutes",
    note2:
      "We do not store your responses or identifying data. Do not navigate away from this tool or you will lose all your progress.",
    route: "/employee-or-contractor",
    id: "workerType",
    icon: constructionHatLogo,
  },

  {
    title: "Layoff Dispute",
    disc1: "Find out if a layoff is illegal and if the worker should receive compensation.",
    note1: "Estimated completion time: 5 minutes",
    note2:
      "We do not store your responses or identifying data. Do not navigate away from this tool or you will lose all your progress.",
    route: "/layoff",
    id: "layoffTool",
    icon: contractLogo,
  },
  {
    title: "Harassment",
    disc1: `Harassment is illegal and unwelcome behavior. However, the purpose of this tool is not to determine if
      you’ve been harassed, rather, to provide you with information about your employers’ duties related to
      workplace harassment, the investigation process, and what a fair investigation should generally involve.`,
    note1: "Estimated completion time: 15 minutes",
    note2:
      "We do not store your responses or identifying data. Do not navigate away from this tool or you will lose all your progress.",
    route: "/harassment",
    id: "harassmentTool",
    icon: personLogo,
  },
  {
    title: "Cut Wages",
    disc1:
      "Cutting a worker's wages may entitle them to compensation under constructive dismissal. Our tool helps you identify if this situation applies.",
    note1: "Estimated completion time: 5 minutes",
    note2:
      "We do not save your progress or identifying data. Do not navigate away from this tool or you will lose all your progress.",
    route: "/wage-cut",
    id: "wageTool",
    icon: personLogo,
  },
  {
    title: "Termination Compensation",
    disc1:
      "Compensation can be offered in a variety of situations, use our Termination Compensation Calculator to find out if your situation qualifies for compensation and determine how much should be offered.",
    note1: "Estimated completion time: 5 minutes",
    note2:
      "We do not store your responses or identifying data. Do not navigate away from this tool or you will lose all your progress.",
    route: "/termination-compensation-calculator",
    id: "tcTool",
    icon: calculatorLogo,
  },
  // {
  //   title: "Covid-19 Vaccine Injury Claim",
  //   disc1:
  //     "Use the Vaccine Mediator reporting site to report suspected side effects of the COVID-19 vaccine. Researchers will use this data to develop tools that will facilitate the resolution of vaccine injury claims.",
  //   note:"We do not store your responses or identifying data. Do not navigate away from this tool or you will lose all your progress."
  //   route: "/vaccine-mediator",
  //   id: "vaccineTool",
  //   icon: vaccineLogo,
  // },
]

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    sx={{
      border: "1rem",
      borderColor: "#99a",
      borderRadius: 8,
      boxShadow:
        "0px 2px 6px 0 rgba(0,0,0,0.2),0px 1px 6px 0px rgba(0,0,0,0.14),0px 1px 6px 0px rgba(0,0,0,0.12)",
      alignSelf: "center",
    }}
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "1.5rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
  flexDirection: "row",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    margin: theme.spacing(1),
  },
  ":hover": {
    backgroundColor: theme.palette.mode === "dark" ? "rgba(255, 255, 255, .3)" : "rgba(0, 0, 0, .2)",
  },
}))

export default function CustomizedAccordions() {
  const [expanded, setExpanded] = React.useState(null)

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  return (
    <Card sx={{ display: "flex", boxShadow: "none" }}>
      <Box
        sx={{
          maxWidth: "45em",
          margin: ".5em 2em .5em 2em",
        }}
        children={data.map((tool) => {
          const { id, title, icon } = tool
          return (
            <MuiAccordion
              key={`accordion-${id}`}
              disableGutters
              elevation={0}
              sx={{
                margin: "1.1rem 0rem",
                padding: -1,
                "&:before": { display: "none" },
              }}
              expanded={expanded === `panel${id}`}
              onChange={handleChange(`panel${id}`)}
            >
              <AccordionSummary
                aria-controls={`panel${id}d-content`}
                id={`panel${id}d-header`}
                children={[
                  <img
                    key={`accordion-img-${id}`}
                    style={{ height: "2.8rem", margin: "0em 2em 0em .5em" }}
                    src={icon}
                    alt={""}
                  />,
                  <Typography
                    key={`accordion-title-${id}`}
                    style={{ alignSelf: "center" }}
                    variant="h4"
                    component={"h2"}
                    fontWeight={"500"}
                    children={title}
                  />,
                ]}
              />
              <MuiAccordionDetails
                // key={`accordion-details-${id}`}
                className="blue-card"
                sx={{
                  margin: "1.5em .25em 0",
                  padding: "1.5em 2em 1.5em 2em  !important",
                }}
              >
                <Typography mb={1} fontSize="medium" variant="body1" children={tool.disc1} />
                <Divider />
                <Typography
                  mt={0.25}
                  mb={0.5}
                  fontSize=".9rem"
                  variant="body1"
                  fontWeight="600"
                  children={tool.note1}
                />
                <Typography mt={".5em"} mb={0.5} variant="body2" fontWeight="400" children={tool.note2} />
                <Link to={tool.route} style={{ textDecoration: "none" }}>
                  <Button
                    sx={{
                      marginTop: 2,
                      padding: "0.5em",
                      width: "10em",
                      borderRadius: "1.25em",
                      fontWeight: "bold",
                    }}
                    variant="contained"
                    children={"Start"}
                  />
                </Link>
              </MuiAccordionDetails>
            </MuiAccordion>
          )
        })}
      />
    </Card>
  )
}
