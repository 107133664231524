import React from "react"
import ReactDOM from "react-dom"
import HttpsRedirect from "react-https-redirect"
import { BrowserRouter } from "react-router-dom"
import App from "./components/app/app.js"
import "./index.css"

// import "./utils/i18n"

ReactDOM.render(
  <HttpsRedirect>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </HttpsRedirect>,
  document.getElementById("root")
)
