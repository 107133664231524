import React, { Fragment } from "react"
import whiteMOCLogo from "../../images/logos/whiteMOCLogo.webp"
import queensWordmark from "../../images/logos/queensWordmark.svg"
import { AppBar, IconButton, Toolbar, Typography } from "@mui/material"
// import MenuIcon from '@mui/icons-material/Menu'
import { Link } from "react-router-dom"

export default function IFrameBorders({ iframeContent, children }) {
  return (
    <Fragment>
      <AppBar
        position="sticky"
        sx={{
          color: "#fff",
          backgroundColor: "#11335B",
        }}
      >
        <Toolbar>
          <Link to={"/"}>
            <img
              src={whiteMOCLogo}
              alt={"MyOpenCourt Logo"}
              style={{ height: "2em", width: "2em" }}
            />
          </Link>
          <Typography variant="h4" component="div" sx={{ margin: 1, flexGrow: 1 }}>
            Powered by the Conflict Analytics Lab
          </Typography>
          {/* <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                >
                    <MenuIcon />
                </IconButton> */}
          <a
            style={{ color: "#fff", margin: "0 .5em 0 .1em" }}
            href="https://myopencourt.org/about-us/"
            target={"_blank"}
            rel="noopener noreferrer"
          >
            About Us
          </a>
          <a
            style={{ color: "#fff", margin: "0 .1em 0 .5em" }}
            href="https://myopencourt.org/blog/"
            target={"_blank"}
            rel="noopener noreferrer"
          >
            Blog
          </a>
        </Toolbar>
      </AppBar>
      {children}

      <AppBar
        position="fixed"
        sx={{
          top: "auto",
          bottom: 0,
          width: "100%",
          backgroundColor: "#ecbb30",
          color: "#000",
        }}
      >
        <Toolbar className="flex-row" sx={{ justifyContent: "space-around" }}>
          <div className="flex-column">
            <div className="flex-row">
              <Typography variant={"title"} children=" A research consortium from" />
              <img
                src={queensWordmark}
                alt="Queen's University"
                style={{ margin: "0 .5em", height: "2.2em", width: "7em" }}
              />
            </div>
            <Typography
              variant={"title"}
              fontSize=".8em"
              children="©2022 Conflict Analytics Lab - All Rights Reserved"
            />
          </div>
          {/* <div className="flex-column" >
            <a href="https://www.linkedin.com/company/conflict-analytics-lab" className="social-media-link" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin"></i>LinkedIn</a>
            <a href="https://twitter.com/conflict_lab" className="social-media-link" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter-square"></i>Twitter</a>
            <a href="https://www.facebook.com/ConflictAnalytics/" className="social-media-link" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-square"></i>Facebook</a>
          </div> */}
        </Toolbar>
      </AppBar>
      <Toolbar data-iframe-height sx={{ padding: "1em" }}></Toolbar>
    </Fragment>
  )
}
