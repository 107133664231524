import React from "react"
import wcDesktopImage from "./../../images/home-tool-images/desktop/workers.webp"
import tccDesktopImage from "./../../images/home-tool-images/desktop/scale-statue.webp"
import layoffDesktopImage from "./../../images/home-tool-images/desktop/frustrated.webp"
import wageCutDesktopImage from "./../../images/home-tool-images/desktop/numbers.webp"
import minWageDesktopImage from "./../../images/home-tool-images/desktop/wages.webp"
import vacationPayDesktopImage from "./../../images/home-tool-images/desktop/vacation.webp"
import financeDesktopImage from "./../../images/home-tool-images/desktop/finance.webp"
import vaccineDesktopImage from "./../../images/home-tool-images/desktop/vaccine.webp"
import ToolHomeSummary from "./../tool-home-summary/tool-home-summary.js"
import QuestionGrid from "./question-grid.js"
import "./home.css"
import "../btn.css"

class Home extends React.PureComponent {
  render() {
    return (
      <React.Fragment>
        <div className="home-content">
          <h1>Tools and Services</h1>
          <p>
            Our tools are created for workers and small-to-medium sized businesses. The development
            of the tools is paid by research grants claimed by the{" "}
            <a href="https://conflictanalytics.queenslaw.ca">
              <u>Conflict Analytics Lab</u>
            </a>{" "}
            at Queen&apos;s University.
          </p>
        </div>

        <div className="tool-summaries">
          <ToolHomeSummary
            imageSrcDesktop={vaccineDesktopImage}
            // imageSrcMobile={vaccineMobileImage}
            toolName={"Vaccine Mediator"}
            description={
              "Use the Vaccine Mediator reporting site to report suspected side effects of the COVID-19 vaccine. Researchers will use this data to develop tools that will facilitate the resolution of vaccine injury claims."
            }
            toolUrl={"/vaccine-mediator"}
            // currPath={this.props.location.pathname}
          />
          <ToolHomeSummary
            imageSrcDesktop={wcDesktopImage}
            // imageSrcMobile={wcMobileImage}
            toolName={"Employee or Contractor?"}
            description="Whether you’re a worker or an employer you should know what rights and benefits you or your worker’s may be entitled to, our tool can help you quickly find out!"
            toolUrl={"/employee-or-contractor"}
            // currPath={this.props.location.pathname}
          />
          <ToolHomeSummary
            imageSrcDesktop={tccDesktopImage}
            // imageSrcMobile={tccMobileImage}
            toolName={"Termination Compensation Calculator"}
            description="Compensation can be offered in a variety of situations, use our Termination Compensation Calculator to find out if your situation qualifies for compensation and determine how much should be offered."
            toolUrl={"/termination-compensation-calculator"}
            // currPath={this.props.location.pathname}
          />
          <ToolHomeSummary
            imageSrcDesktop={wageCutDesktopImage}
            // imageSrcMobile={wageCutMobileImage}
            toolName={"Wage Cut Tool"}
            description="Cutting a worker's wages may entitle them to compensation under constructive dismissal. Our tool helps you identify if this situation applies."
            toolUrl={"/wage-cut"}
            // currPath={this.props.location.pathname}
          />
          <ToolHomeSummary
            imageSrcDesktop={minWageDesktopImage}
            // imageSrcMobile={minWageMobileImage}
            toolName={"Min Wage Tool"}
            description="Minimum wages differ based on location and type of employment. This tool will help you quickly determine the minimum legal wage for a position."
            toolUrl={"/min-wage"}
            // currPath={this.props.location.pathname}
          />
          <ToolHomeSummary
            imageSrcDesktop={vacationPayDesktopImage}
            // imageSrcMobile={vacationPayMobileImage}
            toolName={"Vacation Pay Calculator"}
            description="Vacation compensation is required in most but not all employee / employer arrangements. Independent contractors are not eligible for vacation pay. Determine your vacation compensation entitlement."
            toolUrl={"/vacation-pay"}
            // currPath={this.props.location.pathname}
          />
          <ToolHomeSummary
            imageSrcDesktop={layoffDesktopImage}
            // imageSrcMobile={layoffMobileImage}
            toolName={"Layoff Tool"}
            description="Find out if a layoff is illegal and if the worker should receive compensation."
            toolUrl={"/layoff"}
            // currPath={this.props.location.pathname}
          />
          <ToolHomeSummary
            toolName={"Workplace Harassment Investigation"}
            description="The purpose of this tool is not to determine if
            you' ve been harassed, rather, to provide you with information about an employers' duties related to
            workplace harassment, the investigation process, and what a fair investigation should generally involve."
            // toolUrl={"/"}
            link={
              <button disabled={true} className="btn ">
                {"Coming Soon"}
              </button>
            }
            // currPath={this.props.location.pathname}
          />
          <ToolHomeSummary
            imageSrcDesktop={financeDesktopImage}
            // imageSrcMobile={financeDesktopImage}
            toolName={"Prospectus Exemption Tool"}
            description="Our tool helps business owners understand whether they can raise money from investors. Raising capital normally triggers extensive reporting obligations and costs under the Securities Act, but there are some exemptions which make the private capital markets more accessible."
            toolUrl={"/prospectus-exemption-tool"}
            // currPath={this.props.location.pathname}
          />
        </div>

        <div className="question-grid">
          <h2>Not sure which tool to use?</h2>
          <p>{`Click on your question and we'll take you to the right tool.`}</p>
          <QuestionGrid />
        </div>
      </React.Fragment>
    )
  }
}

export default Home
