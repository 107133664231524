import React from "react"
import { Link } from "react-router-dom"
import "./home.css"
import "../btn.css"

const QuestionGrid = () => (
  <div className="question-grid-flex">
    <Link to="/layoff" className="btn red-btn">
      <p>{`Is my layoff illegal?`}</p>
    </Link>
    <Link to="/employee-or-contractor" className="btn red-btn">
      <p>{`Why are taxes and EI being deducted from my pay?`}</p>
    </Link>
    <Link to="/termination-compensation-calculator" className="btn red-btn">
      <p>{`How long can I be laid off for?`}</p>
    </Link>
    <Link to="/employee-or-contractor" className="btn red-btn">
      <p>{`Why can't I claim employment benefits?`}</p>
    </Link>
    <Link to="/termination-compensation-calculator" className="btn red-btn">
      <p>{`Can my employer cut my wages or hours of work?`}</p>
    </Link>
    <Link to="/employee-or-contractor" className="btn red-btn">
      <p>{`Can my employer stop me from working other jobs?`}</p>
    </Link>
  </div>
)

export default QuestionGrid
