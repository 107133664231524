import React, { lazy, Suspense } from "react"
import { Route, Routes } from "react-router-dom"
import TagManager from "react-gtm-module"
import Home from "../home/home.js"

import "./app.css"

// UI update imports
import { createTheme, ThemeProvider } from "@mui/material"
import ToolsLandingPage from "../views/ToolsLandingPage"
import IFrameBorders from "../views/IFrameBorders.jsx"
import { loadResizer } from "../../utils/iframe/loadResizer.js"

//lazy Components
const WCLanding = lazy(() => import("../survey-controllers/wc/wc-landing.js"))
const WCWorkerTool = lazy(() => import("../survey-controllers/wc/wc-worker-tool.js"))
const WCEmployerTool = lazy(() => import("../survey-controllers/wc/wc-employer-tool.js"))
const TCCLanding = lazy(() => import("../survey-controllers/tcc/tcc-landing.js"))
const TCCWorkerTool = lazy(() => import("../survey-controllers/tcc/tcc-worker-tool.js"))
const TCCEmployerTool = lazy(() => import("../survey-controllers/tcc/tcc-employer-tool.js"))
const MinWageTool = lazy(() => import("../survey-controllers/minWage/MinWageTool.js"))
const VacationPayTool = lazy(() => import("../survey-controllers/vacationPay/VacationPayTool.js"))
const WageCutLanding = lazy(() => import("../survey-controllers/wage-cut/wage-cut-landing.js"))
const WageCutWorkerTool = lazy(() =>
  import("../survey-controllers/wage-cut/wage-cut-worker-tool.js")
)
const WageCutEmployerTool = lazy(() =>
  import("../survey-controllers/wage-cut/wage-cut-employer-tool.js")
)
const LayoffLanding = lazy(() => import("../survey-controllers/layoff/layoff-landing.js"))
const LayoffWorkerTool = lazy(() => import("../survey-controllers/layoff/layoff-worker-tool.js"))
const LayoffEmployerTool = lazy(() =>
  import("../survey-controllers/layoff/layoff-employer-tool.js")
)
const PIPTool = lazy(() => import("../survey-controllers/pip/pip-tool.js"))
const PIPPrototype = lazy(() => import("../survey-controllers/pip/pip-bi-prototype.js"))
const InvProspectusTool = lazy(() => import("../survey-controllers/inv/inv-tool.js"))
const VaccineTool = lazy(() => import("../survey-controllers/vaccine/vaccine-tool.js"))
const StatMinimumTable = lazy(() => import("../stat-minimum-table/stat-minimum-table.js"))
const NotFound = lazy(() => import("../not-found/not-found.js"))

const theme = createTheme({
  palette: {
    primary: {
      main: "#9d1939",
      dark: "#602",
    },
    secondary: {
      main: "#11335B",
      dark: "#11335B",
    },
  },
  typography: {
    h1: {
      fontSize: "2rem",
    },
    h2: {
      fontSize: "1.5rem",
    },
    h3: {
      fontSize: "1.17rem",
    },
    h4: {
      fontSize: "1.0rem",
    },
    h5: {
      fontSize: "0.83rem",
    },
    h6: {
      fontSize: "0.67rem",
    },
    fontFamily: `Open Sans`,
  },
  // Link: {
  //   // defaultProps:{
  //   //   style:{
  //   //   }
  //   // }
  // },
  components: {
    MuiButtonBase: {
      defaultProps: {
        style: {
          textTransform: "capitalize",
          fontSize: "1.15em",
          textDecoration: "none",
          // borderRadius: "1.15rem"
        },
      },
    },
    MuiLink: {
      defaultProps: {
        style: {
          textDecoration: "none",
          color: "#252",
        },
      },
    },
  },
})

class App extends React.Component {
  constructor(props) {
    super(props)
    loadResizer()
    // Connect to Google Tag Manager
    const tagManagerArgs = {
      gtmId: "GTM-NGZ3RX8",
    }
    TagManager.initialize(tagManagerArgs)
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <div className="app">
          <IFrameBorders>{this.renderContent()}</IFrameBorders>
        </div>
      </ThemeProvider>
    )
  }

  renderContent() {
    return (
      <Suspense fallback={""}>
        {/* blank fallback avoids <100 ms loading state flash change to skeleton later  */}
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/landingPage" element={<ToolsLandingPage />} />
          <Route exact path="/am-i-an-employee-or-a-contractor" element={<WCWorkerTool />} />
          <Route exact path="/employee-or-contractor" element={<WCLanding />} />
          <Route exact path="/employee-or-contractor/worker" element={<WCWorkerTool />} />
          <Route exact path="/employee-or-contractor/employer" element={<WCEmployerTool />} />
          <Route exact path="/termination-compensation-calculator" element={<TCCLanding />} />
          <Route
            exact
            path="/termination-compensation-calculator/worker"
            element={<TCCWorkerTool />}
          />
          <Route
            exact
            path="/termination-compensation-calculator/employer"
            element={<TCCEmployerTool />}
          />
          <Route exact path={"/min-wage"} element={<MinWageTool />} />
          <Route exact path={"/vacation-pay"} element={<VacationPayTool />} />
          <Route exact path="/wage-cut" element={<WageCutLanding />} />
          <Route exact path="/wage-cut/worker" element={<WageCutWorkerTool />} />
          <Route exact path="/wage-cut/employer" element={<WageCutEmployerTool />} />
          <Route exact path="/layoff" element={<LayoffLanding />} />
          <Route exact path="/layoff/worker" element={<LayoffWorkerTool />} />
          <Route exact path="/layoff/employer" element={<LayoffEmployerTool />} />
          <Route exact path="/personal-injury-tool" element={<PIPTool />} />
          <Route exact path="/pip-private-prototype" element={<PIPPrototype />} />
          <Route exact path="/prospectus-exemption-tool" element={<InvProspectusTool />} />
          <Route exact path="/vaccine-mediator" element={<VaccineTool />} />
          <Route exact path="/statutory-minimum-table" element={<StatMinimumTable />} />
          <Route element={<NotFound />} />
        </Routes>
      </Suspense>
    )
  }
}

export default App
