import React from "react"
import { Link } from "react-router-dom"
import TextOverImage from "./../basic-views/text-over-image/text-over-image.js"
import "./tool-home-summary.css"
import "./../btn.css"

class ToolHomeSummary extends React.PureComponent {
  constructor(props) {
    super(props)
    this.scrollToTop = this.scrollToTop.bind(this)
  }

  scrollToTop() {
    // Scroll to the top of the page.
    // Without this, if we already scrolled down we will go to the next
    // page and start already scrolled down.
    window.scrollTo(0, 0)
  }

  render() {
    const linkDestination = {
      pathname: this.props.toolUrl,
      state: { prevPath: this.props.currPath },
    }

    return (
      <div className="tool-home-summary">
        <Link to={linkDestination}>
          <TextOverImage
            imageSrcDesktop={this.props.imageSrcDesktop}
            imageSrcMobile={this.props.imageSrcMobile}
            text={this.props.toolName}
          />
        </Link>
        <div className="under-image">
          <p>{this.props.description}</p>
        </div>
        {this.props.link || (
          <Link to={linkDestination} className="btn red-btn" onClick={this.scrollToTop}>{`Get Started`}</Link>
        )}
      </div>
    )
  }
}

export default ToolHomeSummary
